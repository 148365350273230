<template>
  <transition name="el-fade-in">
    <div :class="['components-layout-floatBtn', { visibile: visibile }]">
      <ul>
        <li>
          <a href="tel:400-002-6622">
            <img class="icon" src="../../assets/images/xf-ico1.png" alt="" />
            <span>试用</span></a
          >
          <div class="telsDiv">
            <div>咨询：021-62561666</div>
          </div>
        </li>
        <li @click="onDemoClick">
          <img class="icon" src="../../assets/images/xf-ico2.png" alt="" />
          <span>试用</span>
        </li>
        <li>
          <img class="icon" src="../../assets/images/xf-ico3.png" alt="" />
          <span>微信</span>
          <div class="wxsDiv">
            <img class="icon stxt" src="../../assets/images/xf-ewm.jpg" />
            <div class="stxt">微信公众号</div>
          </div>
        </li>
        <li @click="goTop">
          <img class="icon" src="../../assets/images/xf-ico4.png" alt="" />
          <span>顶部</span>
        </li>
        <li class="shouqi">
          <span>收起</span><i class="el-icon-arrow-right"></i>
        </li>
      </ul>
      <demo-dialog ref="demoDialog" />
    </div>
  </transition>
</template>
<script>
import DemoDialog from "@/components/home/DemoDialog.vue";
export default {
  name: "components-layout-floatBtn",
  components: { DemoDialog },
  props: {
    visibileHeight: {
      type: [String, Number],
      default: 200,
    },
  },
  data() {
    return {
      scrollTop: 0,
      visibile: false, //默认隐藏
    };
  },
  mounted() {
    window.addEventListener("scroll", this.onScrollTop);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScrollTop);
  },
  methods: {
    goTop() {
      let that = this;
      let timer = setInterval(() => {
        let speed = Math.floor(-that.scrollTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          that.scrollTop + speed;
        if (that.scrollTop == 0) {
          clearInterval(timer);
        }
      }, 16);
    },
    onScrollTop() {
      let that = this;
      let visibileHeight = that.visibileHeight;
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      that.scrollTop = scrollTop;
      if (that.scrollTop >= visibileHeight) {
        that.visibile = true;
      } else {
        that.visibile = false;
      }
    },
    onDemoClick() {
      this.$refs.demoDialog.show();
    },
  },
};
</script>
<style lang="less" scoped>
.components-layout-floatBtn {
  position: fixed;
  right: 4px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  text-align: center;
  border-radius: 70px;
  height: auto;
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease;
  &.visibile {
    visibility: visible;
    opacity: 1;
  }
  ul {
    padding-top: 15px;
  }
  li {
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    position: relative;
    padding: 10px 0;
    cursor: pointer;
    color: #666;
    span {
      display: inline-block;
      font-size: 16px;
    }
    &:not(:last-child):after {
      content: "";
      width: 57.1428%;
      position: absolute;
      bottom: 0;
      height: 1px;
      border-bottom: dashed 1px #d2d2d2;
      left: 50%;
      transform: translateX(-50%);
    }
    a {
      color: #666;
    }
    .icon {
      margin-bottom: 5px;
      transition: all 0.3s ease 0s;
      display: block;
      background: url(../../assets/images/xf-bg.png) no-repeat center center;
    }
    .telsDiv {
      position: absolute;
      right: 100%;
      top: 50%;
      height: 50px;
      line-height: 50px;
      padding: 0 35px;
      background-color: #1f63d2;
      border-radius: 50px 0 0 50px;
      transform: translateY(-50%);
      visibility: hidden;
      opacity: 0;
      transition: all 0.5s ease 0s;
      div {
        white-space: nowrap;
        font-size: 16px;
        color: #fff;
      }
    }
    .wxsDiv {
      position: absolute;
      top: 0;
      right: 100%;
      padding: 20px 20px 10px;
      width: 134px;
      background-color: #fff;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
      margin-right: 6px;
      visibility: hidden;
      opacity: 0;
      transition: all 0.5s ease 0s;
    }
    &.shouqi {
      background-color: #1f63d2;
      border-radius: 0px 0px 100px 100px;
      height: 38px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: row;
      color: #fff;
      cursor: pointer;
    }
    &:hover {
      .wxsDiv {
        visibility: visible;
        opacity: 1;
        .stxt {
          color: #333;
        }
      }
      .telsDiv {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}
@media screen and (max-width: 1560px) {
  .components-layout-floatBtn {
    li {
      font-size: 12px;
      width: 50px;
      span {
        font-size: 14px;
      }
      a {
        font-size: 12px;
      }
      .icon {
        // width: 20px;
      }
      &.shouqi {
        font-size: 12px;
      }
    }
  }
}
@media screen and (max-width: 680px) and (orientation: portrait) {
  .components-layout-floatBtn {
    li {
      width: 45px;
      padding: 8px 0;
      .icon {
        margin-bottom: 2px;
        width: 20px;
      }
    }
  }
}
</style>
