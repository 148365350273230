<template>
  <div class="components-layout-footer">
    <div class="footer-container">
      <div class="footer-content">
        <div class="contentLeft">
          <div class="footer-head">
            <div class="footer-right">
              <img :src="imgList.logo" alt="" />
            </div>
            <div class="footer-left">
              <!-- <img :src="imgList.fx1" alt="" /> -->
              <div class="left-item">
                <img :src="imgList.fx2" alt="" />
              </div>
              <div class="left-item">
                <img :src="imgList.fx3" alt="" />
              </div>
              <div class="left-item">
                <img :src="imgList.fx4" alt="" />
              </div>
            </div>
          </div>

          <div class="content-middleOne">
            <div class="content-left">
              <img :src="imgList.ewm" alt="" />
            </div>
            <ul>
              <li v-for="(item, index) in footer.introList" :key="index">
                {{ item.title }}
              </li>
            </ul>
          </div>
        </div>

        <div class="content-middle">
          <ul v-for="(itemList, index) in footer.producList" :key="index">
            <li
              v-for="(childItem, childIndex) in itemList"
              :key="index + '-' + childIndex"
              :class="{ contentHead: childIndex == 0 }"
            >
              {{ childItem.title }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="content-bottom">
      <ul class="footer-bottom">
        <li v-for="(item, index) in footer.bottomList" :key="index">
          <span v-if="item.backUrl === '' ">{{ item.title }}</span>
          <a v-else-if="item.backUrl !== '' " :href="item.backUrl" target="_blank" style="color:#FFFFFF;">
            <img v-if="item.iconUrl" :src="item.iconUrl" width="12" height="12" border="0"/>
            {{ item.title }}
          </a>

        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: "components-layout-footer",
  data() {
    return {
      imgList: {
        logo: require("@/assets/images/logo.png"),
        fx1: require("@/assets/images/fx-ico1.png"),
        fx2: require("@/assets/images/foot/in.png"),
        fx3: require("@/assets/images/foot/wechact.png"),
        fx4: require("@/assets/images/foot/zhihu.png"),
        ewm: require("@/assets/images/foot/icon.png"),
      },
      footer: {
        introList: [
          {
            name: "title",
            title: "咨询热线：021-62561666",
          },
          {
            name: "title",
            title: "加入我们：hr@npnets.com",
          },
          {
            name: "title",
            title: "商务合作：market@npnets.com",
          },
          {
            name: "title",
            title: "公司地址：上海市徐汇区桂林路418号7号楼3楼",
          },
        ],
        producList: [
          [
            {
              name: "title",
              title: "产品服务",
            },
            {
              name: "title",
              title: "话加",
            },
            {
              name: "title",
              title: "话倍",
            },
            {
              name: "title",
              title: "话数",
            },
            {
              name: "title",
              title: "话云",
            },
          ],
          [
            {
              name: "title",
              title: "行业方案",
            },
            {
              name: "title",
              title: "运营商",
            },
            {
              name: "title",
              title: "金融",
            },
            {
              name: "title",
              title: "互联网",
            },
          ],
          [
            {
              name: "title",
              title: "客户案例",
            },
            {
              name: "title",
              title: "中国联通",
            },
            {
              name: "title",
              title: "阳光保险",
            },
            {
              name: "title",
              title: "阿里云",
            },
          ],
          [
            {
              name: "title",
              title: "新闻中心",
            },
            {
              name: "title",
              title: "企业动态",
            },
            {
              name: "title",
              title: "行业资讯",
            },
            {
              name: "title",
              title: "升级日志",
            },
            {
              name: "title",
              title: "最近签约",
            },
          ],
          [
            {
              name: "title",
              title: "关于航动",
            },
            {
              name: "title",
              title: "公司介绍",
            },
            {
              name: "title",
              title: "联系我们",
            },
            {
              name: "title",
              title: "招贤纳士",
            },
          ],
        ],
        bottomList: [
          {
            name: "title",
            title: "Copyright©2022",
            backUrl: "",
            iconUrl: "",
          },
          {
            name: "title",
            title: "上海航动科技有限公司",
            backUrl: "",
            iconUrl: "",
          },
          {
            name: "title",
            title: "增值电信业务经营许可证 B2-20150849",
            backUrl: "",
            iconUrl: "",
          },
          {
            name: "title",
            title: "沪ICP备13039038号-1",
            backUrl: "https://beian.miit.gov.cn",
            iconUrl: "",
          },
          {
            name: "title",
            title: "沪公网安备31010402333805",
            backUrl: "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010402009207",
            iconUrl: [require("@//assets/police.jpg")],
          },
        ],
      },
    };
  },
};
</script>
<style lang="less" scoped>
.components-layout-footer {
  // background: url(../../assets/images/foot-bg.jpg) no-repeat center center/cover;
  width: 100%;
  margin: auto;

  position: relative;
  &::before {
    content: "";
    position: absolute;
    background: url(../../assets/images/foot/footer-left.png) no-repeat;
    background-size: contain;
    width: 10%;
    height: 100%;
    left: 0;
    top: 0;
  }
  &::after {
    content: "";
    position: absolute;
    background: url(../../assets/images/foot/footer-right.png) no-repeat;
    background-size: contain;
    width: 10%;
    height: 58%;

    right: 0;
    top: 49%;
  }

  .footer-container {
    width: 1408px;
    // width: 74%;
    margin: auto;
  }

  .footer-content {
    display: flex;
    // justify-content: space-between;
    align-items: center;
    border-bottom: solid 1px #dde0e3;
    padding: 1.45rem /* 40/80 */ 0 1.3rem /* 45/80 */;
    .contentLeft {
      padding-right: 88px;
      // &::after {
      //   content: "";
      //   position: absolute;
      //   width: 1px;
      //   height: 176px;
      //   background: #ccc;

      //   right: 56%;
      //   top: 27%;
      // }
    }

    & > li {
      text-align: left;
      cursor: pointer;

      li {
        font-size: 0.225rem /* 16/80 */;
        line-height: 30px /* 30/80 */;
        color: #555;

        &:hover {
          color: #1f63d2;
        }
      }
    }
    .footer-head {
      display: flex;

      // padding-top: 0.8125rem /* 65/80 */;
      // padding-bottom: 0.375rem /* 30/80 */;
      // transform: translateX(279px);
      justify-content: space-between;

      .footer-left {
        display: flex;
        justify-content: space-evenly;
        align-items: center;

        .left-item {
          margin: 0 10px;
          img {
            width: 37px;
            height: 37px;
          }
        }
      }
      .footer-right {
        height: 100%;
        img {
          height: 100%;
        }
      }
    }
    .content-middleOne {
      // width: 5rem /* 400/80 */;
      display: flex;
      justify-content: space-around;
      margin-top: 20px;
      .content-left {
        padding-right: 0.4rem;
      }
      img {
        max-width: 126px;
      }
      ul li {
        text-align: left;
        font-size: 16px;
        line-height: 33px;
        letter-spacing: 1px;

        font-family: SimHei;
        color: #666666;
      }
    }

    .content-middle {
      display: flex;
      justify-content: space-between;

      flex: 1;
      // justify-content: space-evenly;
      padding-left: 94px;
      border-left: 1px solid #ccc;

      ul {
        li {
          line-height: 33px;
          font-size: 16px;
          text-align: left;
          color: #666666;

          font-family: Adobe Heiti Std;
          font-weight: normal;
        }
        // margin-right: 1.025rem /* 82/80 */;
        .contentHead {
          font-size: 18px /* 18/80 */;

          font-family: SimHei;
          color: #333333;
        }
      }
    }

    .contentHead {
      font-size: 0.325rem /* 24/80 */;
      color: #333333;
      margin-bottom: 30px /* 30/80 */;
    }
  }

  .content-bottom {
    background: #333;

    .footer-bottom {
      width: 74%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding: 0.2375rem 0;
      font-size: 0.175rem;
      color: #fff;

      li {
        color: #fff;
        margin: 0 0.0625rem /* 5/80 */;
      }
    }
  }
}
@media screen and (max-width: 1500px) {
  .components-layout-footer {
    .footer-container {
      width: 78%;
      .footer-content {
        .footer-head .footer-left {
        }
        .contentLeft {
          &::after {
            right: 51%;
          }
        }
      }
    }
    .content-bottom {
      .footer-bottom {
        // width: 80%;
      }
    }
  }
}
@media screen and (max-width: 1366px) {
  .components-layout-footer {
    .footer-container {
      width: 78%;
      .footer-content {
        .contentLeft {
          padding-right: 60px;
        }
        .content-middle {
          padding-left: 60px;
        }
      }
    }
    .content-bottom {
      .footer-bottom {
        width: 77%;
        li {
          font-size: 14px;
          // width: 85%;
        }
      }
    }

    .footer-content {
      .content-middle ul {
        .contentHead {
          font-size: 16px;
        }
        li {
          font-size: 14px;
        }
      }
      .content-middleOne ul li {
        font-size: 14px;
      }
      .contentLeft::after {
        height: 165px;
      }
    }
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .components-layout-footer {
    .footer-container {
      width: 92%;
      // width: 80%;
      .footer-content {
        .footer-head .footer-left {
        }
        .contentLeft {
          padding-right: 35px;
        }
        .content-middle {
          padding-left: 35px;
        }
      }
    }
  }
}
@media screen and (min-width: 769px) and (max-width: 991px) {
  .components-layout-footer {
    .footer-container {
      width: 92%;
      // width: 80%;
      .footer-content {
        .footer-head .footer-left {
        }
        .contentLeft {
          padding-right: 10px;
          width: 50%;
          &::after {
            width: 0;
          }
        }
        .contentHead {
          margin-top: -30px;
        }
        .content-middle {
          padding-left: 10px;
          border-left: 1px solid #ccc;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .components-layout-footer {
    &::before {
      display: none;
    }
    &::after {
      display: none;
    }

    .footer-content {
      .content-middle {
        display: none !important;
      }
      .contentLeft::after {
        display: none;
      }
    }
  }
}
</style>
