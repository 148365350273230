<template>
  <div class="views-layout" id="views-layout">
    <Header></Header>
      <router-view class="view-container" />
    <Footer></Footer>
    <float-btn></float-btn>
  </div>
</template>
<script>

import Header from "@/components/layout/Header.vue";
import Footer from "@/components/layout/Footer.vue";
import FloatBtn from "@/components/layout/FloatBtn.vue";
export default {
  name: "views-layout",
  components: { Header, Footer, FloatBtn },
  watch:{
    $route(){
      window.scroll(0,0)//切换路由时滚动条滚动到底部
    }
  },
  mounted() {

  },
  data() {
    return {};
  },
};
</script>
<style lang="less" scoped>
.views-layout {
  width: 100%;
  height: 100%;
  text-align: center;
  .view-container {
    margin-top: 90px;
  }
}

@media screen and (max-width: 1560px) {
  .view-container {
    margin-top: 75px !important;
  }
}
@media screen and (max-width: 680px) and (orientation: portrait) {
  .view-container {
    margin-top: 50px !important;
  }
}
</style>
