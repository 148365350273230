<template>
  <el-dialog
    class="components-home-demoDialog"
    :visible.sync="isShow"
    :close-on-click-modal="false"
    :append-to-body="true"
  >
    <div class="apply-wrapper">
      <apply-demo class="apply-demo" />
      <el-row
        class="close-btn"
        @click.native="hide"
      >
        <img
          :src="closeIcon"
          alt=""
        >
        关闭页面
      </el-row>
    </div>
  </el-dialog>
</template>
<script>
import ApplyDemo from "@/components/common/ApplyDemo.vue";
export default {
  name: "components-home-demoDialog",
  components: { ApplyDemo },
  props: {},
  data() {
    return {
      isShow: false,
      closeIcon: require("@/assets/images/tanBox-x.png"),
    };
  },
  methods: {
    show() {
      this.isShow = true;
    },
    hide() {
      this.isShow = false;
    },
  },
};
</script>
<style lang="less" scoped>
.components-home-demoDialog {
  width: 100%;
  /deep/ .el-dialog {
    .el-dialog__header {
      padding: 0;
    }
    .el-dialog__body {
      padding: 0;
    }
  }
  .apply-demo {
    margin: 0 !important;
  }
  .apply-wrapper {
    position: fixed;
    top: 4.124%;
    left: 4.167%;
    width: 91.666%;
    height: 91.752%;
    .close-btn {
      position: absolute;
      top: 40px;
      right: 80px;
      font-size: 16px;
      color: #333;
      cursor: pointer;
      height: 20px;
      line-height: 20px;
      img {
        margin-right: 10px;
        float: left;
      }
    }
  }
}
@media screen and (max-width: 1600px) {
  .components-home-demoDialog {
    .apply-wrapper {
      .close-btn {
        font-size: 14px;
        right: 50px;
      }
    }
  }
}
/* iPad竖板显屏 - portrait指定输出设备中的页面可见区域高度大于或等于宽度 */
@media screen and (max-width: 992px) and (orientation: portrait) {
  .components-home-demoDialog {
    .apply-wrapper {
      .close-btn {
        color: #fff;
        right: 25px;
        top: 25px;
      }
    }
  }
}
@media screen and (max-width: 680px) and (orientation: portrait) {
  .components-home-demoDialog {
    .apply-wrapper {
      .close-btn {
        font-size: 0;
        right: 15px;
        top: 15px;
        img {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
