<template>
  <el-header class="components-layout-header">
    <el-row class="container">
      <el-row class="logo">
        <img :src="imgList.logo" alt="" />
      </el-row>
      <el-row class="nav-content">
        <el-menu
          :default-active="activeIndex"
          class="header-menu-horizontal"
          mode="horizontal"
          @select="handleSelect"
        >
          <el-menu-item
            v-for="(item, index) in navList"
            :key="item.name"
            :index="index.toString()"
          >
            <span>{{ item.title }} </span>
            <i></i>
          </el-menu-item>
        </el-menu>
        <ul class="other-nav">
          <li>
          <a href="https://www.huaplus.com/"> 
           <img :src="imgList.yunying" alt="" />
          运营中心
          </a>
          </li>
          <li>
            <img :src="imgList.search" alt="" />
          </li>
          <li class="collapseBtn" @click="onCollapseClick">
            <img :src="imgList.collapse" alt="" />
          </li>
        </ul>
      </el-row>
    </el-row>
    <div
      :class="['header-mobile', { show: isVisible }]"
      @click.self="onCloseClick"
    >
      <el-row :class="['header-menu-vertical', { smaller: isClose }]">
        <el-row class="close-btn" @click.native.stop.prevent="onCloseClick">
          <img :src="imgList.close" alt="" />
        </el-row>
        <el-menu :default-active="activeIndex" @select="handleSelect">
          <el-menu-item
            v-for="(item, index) in navList"
            :key="item.name"
            :index="index.toString()"
            @click.native.stop.prevent="onCloseClick"
            ><span>{{ item.title }}</span></el-menu-item
          >
        </el-menu>
      </el-row>
    </div>
  </el-header>
</template>

<script>
export default {
  name: "components-layout-header",
  data() {
    return {
      imgList: {
        logo: require("@/assets/images/logo.png"),
        yunying: require("@/assets/images/head-yunying.svg"),
        search: require("@/assets/images/head-sch.svg"),
        collapse: require("@/assets/images/m_menuimg01.png"),
        close: require("@/assets/images/close.png"),
      },
      activeIndex: "0",
      isVisible: false,
      isClose: false,
      navList: [
        {
          name: "home",
          title: "首页",
        },
        {
          name: "product",
          title: "产品服务",
        },
        {
          name: "solution",
          title: "行业方案",
        },
        {
          name: "customer",
          title: "客户案例",
        },
        {
          name: "news",
          title: "新闻中心",
        },
        {
          name: "about",
          title: "关于我们",
        },
      ],
    };
  },
  methods: {
    onCollapseClick() {
      this.isVisible = !this.isVisible;
    },
    onCloseClick() {
      this.isClose = true;
      setTimeout(() => {
        this.isVisible = false;
        this.isClose = false;
      }, 290);
    },
    handleSelect(key) {
      let { name } = this.navList[key];
      this.$router.push({ name }).catch((err) => err);
    },
  },
};
</script>
<style lang="less" scoped>
.components-layout-header {
  height: 90px !important;
  line-height: 90px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 9;
  text-align: center;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  .container {
    margin: 0 auto;
    height: 100%;
    width: 100% !important;
    padding-left: 1.5rem !important;
    padding-right: 1rem !important;
  }
  .logo {
    float: left;
    position: relative;
    width: 1.9375rem;
    height: 100%;
    display: flex;
    align-items: center;
    img {
      height: 55%;
    }
  }
  .nav-content {
    float: right;
    display: flex;
    align-items: center;
  }
  .header-menu-horizontal {
    &.el-menu--horizontal {
      border-bottom: 0;
    }
    .el-menu-item {
      padding: 0 0.375rem;
      font-size: 16px;
      color: #8f96aa;
      background-color: #fff !important;
      border-bottom: 0;
      position: relative;
      cursor: pointer;

      &.is-active {
        color: #2056ad;
      }
      &:nth-child(2) {
        i {
          border: solid #8f96aa;
          border-width: 0 1px 1px 0;
          display: inline-block;
          padding: 2px;
          transform: rotate(45deg);
          margin-left: 3px;
        }
      }
      span {
        position: relative;
        height: 100%;
        display: inline-block;

        &::before,
        &::after {
          content: "";
          width: 0;
          height: 3px;
          background-color: #2056ad;
          position: absolute;
          bottom: 0;
          opacity: 0;
          transition: all 0.5s ease;
        }
        &:after {
          left: 0;
        }
        &:before {
          right: 0;
        }
      }
      //去掉小划线
      // &:hover {
      //   span {
      //     &:after,
      //     &:before {
      //       width: 100%;
      //       bottom: 5px;
      //       opacity: 1;
      //     }
      //   }
      // }
    }
  }
  .other-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;

    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      color: #333;
      position: relative;
      cursor: pointer;
      &:first-child {
        margin-left: 30px;
        padding-left: 55px;
        margin-right: 75px;
        &:before {
          content: "";
          width: 1px;
          height: 16px;
          background-color: #dcdcdc;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }
      }
    }
    .collapseBtn {
      display: none;
    }
    img {
      width: 20px;
      margin-right: 10px;
    }
  }
      .other-nav a{
        display: flex;
        align-items: center;
      color: #729ccd;
    transition: color .3s ease;
    }
  .header-mobile {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    visibility: hidden;
    display: none;
    &.show {
      display: inline-block;
      visibility: visible;
    }
    .header-menu-vertical {
      position: absolute;
      left: 0;
      top: 0px;
      z-index: 100;
      width: 80%;
      background: #fff;
      height: 100%;
      overflow-y: auto;
      padding-right: 20px;
      transition: all 0.3s ease;
      animation: bigger 0.3s 1 linear;
      &.smaller {
        animation: smaller 0.3s linear;
      }
      .close-btn {
        position: relative;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        cursor: pointer;
      }
      .el-menu-item {
        border-bottom: 1px solid #f0f0f0;
        height: 45px;
        color: #333;
        line-height: 45px;
        padding-left: 4%;
        font-size: 15px;
        text-align: left;
        &:first-child {
          border-top: 1px solid #f0f0f0;
        }
      }
    }
  }
}
@keyframes bigger {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes smaller {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
@media (min-width: 768px) {
  .components-layout-header {
    .container {
      // width: 750px;
    }
  }
}
@media (min-width: 992px) {
  .components-layout-header {
    .container {
      width: 970px;
    }
  }
}
@media (min-width: 1200px) {
  .components-layout-header {
    .container {
      width: 83%;
      padding-right: 0;
      padding-left: 0;
      // max-width: 1760px;
    }
  }
}

@media screen and (max-width: 1560px) {
  .components-layout-header {
    height: 75px !important;
    line-height: 75px;
    .header-menu-horizontal {
      .el-menu-item {
        font-size: 14px;
      }
    }
    .other-nav {
      li {
        font-size: 14px;
        &:first-child {
          margin-right: 30px;
          margin-left: 20px;
          padding-left: 40px;
        }
      }
    }
  }
}
@media screen and (max-width: 1366px) {
  .components-layout-header {
    height: 75px !important;
    line-height: 75px;
    .header-menu-horizontal {
      .el-menu-item {
        font-size: 14px;
        padding: 0 22px;
      }
    }
    .other-nav {
      li {
        font-size: 14px;
        &:first-child {
          margin-right: 30px;
          margin-left: 20px;
          padding-left: 40px;
        }
      }
    }
  }
}
/* iPad横板显屏 - landscape指定输出设备中的页面可见区域高度小于宽度*/
@media screen and (max-width: 1200px) {
  .components-layout-header {
    .header-menu-horizontal {
      .el-menu-item {
        padding: 0 8px;
      }
    }
  }
}
/* iPad竖板显屏 - portrait指定输出设备中的页面可见区域高度大于或等于宽度 */
@media screen and (max-width: 992px) {
  .components-layout-header {
    .container {
      padding-right: 0 !important;
      .header-menu-horizontal {
      }

      .logo {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
      }
      .other-nav {
        li {
          &:first-child {
            margin-right: 20px;
            margin-left: 15px;
            padding-left: 20px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .components-layout-header {
    .container {
      .nav-content {
        .header-menu-horizontal {
          display: none;
        }
      }

      .other-nav {
        .collapseBtn {
          margin-left: 35px;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}

/* 手机端 - portrait指定输出设备中的页面可见区域高度大于或等于宽度*/
@media screen and (max-width: 680px) and (orientation: portrait) {
  .components-layout-header {
    height: 50px !important;
    line-height: 50px;
    padding-right: 15px;
    padding-left: 15px;
    .logo {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
    }
    .other-nav {
      li {
        font-size: 12px;
        &:first-child {
          margin-right: 20px;
          &::before {
            display: none;
          }
        }
      }
      img {
        width: 13px;
      }
      .collapseBtn {
        margin-left: 25px;
        img {
          width: 24px;
        }
      }
    }
  }
}
@media screen and (max-width: 480px) {
  .nav-content {
    height: 50px;
  }
  .other-nav {
    li {
      font-size: 12px;
      &:first-child {
        margin-right: 0px !important;
        display: none;
        &::before {
          display: none;
        }
      }
      &:nth-child(2) {
        margin: 0 10px;
      }
    }
    img {
      width: 13px;
    }
    .collapseBtn {
      margin-left: 0px !important;
      img {
        width: 24px;
      }
    }
  }
}
</style>
